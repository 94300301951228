import React, {useContext, useState} from 'react';
import {useNavigate, Link} from "react-router-dom";
import {AuthContext} from "../../contexts/AuthContext";

function Profile(props) {
    const [isOpen, setIsOpen] = useState(false);
    const {logout} = useContext(AuthContext);
    const navigate = useNavigate();
    const handleLogout = () => {
        logout();
        navigate('/');
    };

    // Function to toggle dropdown state
    const Profile = () => {
        setIsOpen(!isOpen);
    };
    return (
        <div className="telemetry-profile">
            <div onClick={Profile} className="telemetry-avatar">
                <img src={props.image} alt="hello"/>
            </div>

            {/* Dropdown content */}
            {isOpen && (
                <ul>
                   <li><Link to={"/profile"}>My Profile <span className="link-icon"><svg width="14" height="18"
                                                                                viewBox="0 0 14 18" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg"><path
                        d="M9.35735 7.19036C9.98247 6.56523 10.3337 5.71739 10.3337 4.83333C10.3337 3.94928 9.98247 3.10143 9.35735 2.47631C8.73223 1.85119 7.88438 1.5 7.00033 1.5C6.11627 1.5 5.26842 1.85119 4.6433 2.47631C4.01818 3.10143 3.66699 3.94928 3.66699 4.83333C3.66699 5.71739 4.01818 6.56523 4.6433 7.19036C5.26842 7.81548 6.11627 8.16667 7.00033 8.16667C7.88438 8.16667 8.73223 7.81548 9.35735 7.19036Z"
                        stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/><path
                        d="M2.87554 12.3752C3.9695 11.2812 5.45323 10.6667 7.00033 10.6667C8.54742 10.6667 10.0312 11.2812 11.1251 12.3752C12.2191 13.4692 12.8337 14.9529 12.8337 16.5H1.16699C1.16699 14.9529 1.78157 13.4692 2.87554 12.3752Z"
                        stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round"
                        strokeLinejoin="round"/></svg></span></Link></li>
                    <li><a to={"#"}>My Earnings<span className="link-icon"><svg width="18" height="14"
                                                                                viewBox="0 0 18 14" fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
				<path
                    d="M13.1667 4.49999V2.83332C13.1667 2.3913 12.9911 1.96737 12.6785 1.65481C12.366 1.34225 11.942 1.16666 11.5 1.16666H3.16667C2.72464 1.16666 2.30072 1.34225 1.98816 1.65481C1.67559 1.96737 1.5 2.3913 1.5 2.83332V7.83332C1.5 8.27535 1.67559 8.69927 1.98816 9.01184C2.30072 9.3244 2.72464 9.49999 3.16667 9.49999H4.83333M6.5 12.8333H14.8333C15.2754 12.8333 15.6993 12.6577 16.0118 12.3452C16.3244 12.0326 16.5 11.6087 16.5 11.1667V6.16666C16.5 5.72463 16.3244 5.30071 16.0118 4.98815C15.6993 4.67559 15.2754 4.49999 14.8333 4.49999H6.5C6.05797 4.49999 5.63405 4.67559 5.32149 4.98815C5.00893 5.30071 4.83333 5.72463 4.83333 6.16666V11.1667C4.83333 11.6087 5.00893 12.0326 5.32149 12.3452C5.63405 12.6577 6.05797 12.8333 6.5 12.8333ZM12.3333 8.66666C12.3333 9.10868 12.1577 9.53261 11.8452 9.84517C11.5326 10.1577 11.1087 10.3333 10.6667 10.3333C10.2246 10.3333 9.80072 10.1577 9.48816 9.84517C9.17559 9.53261 9 9.10868 9 8.66666C9 8.22463 9.17559 7.80071 9.48816 7.48815C9.80072 7.17559 10.2246 6.99999 10.6667 6.99999C11.1087 6.99999 11.5326 7.17559 11.8452 7.48815C12.1577 7.80071 12.3333 8.22463 12.3333 8.66666Z"
                    stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
				</svg></span></a></li>
                    <li><a to={"#"}>Notification<span className="link-icon"><svg width="14" height="17"
                                                                                 viewBox="0 0 14 17" fill="none"
                                                                                 xmlns="http://www.w3.org/2000/svg">
				<path
                    d="M6.99967 16.3333C7.91634 16.3333 8.66634 15.5833 8.66634 14.6667H5.33301C5.33301 15.5833 6.08301 16.3333 6.99967 16.3333ZM11.9997 11.3333V7.16668C11.9997 4.60834 10.6413 2.46668 8.24967 1.90001V1.33334C8.24967 0.641677 7.69134 0.0833435 6.99967 0.0833435C6.30801 0.0833435 5.74967 0.641677 5.74967 1.33334V1.90001C3.36634 2.46668 1.99967 4.60001 1.99967 7.16668V11.3333L0.333008 13V13.8333H13.6663V13L11.9997 11.3333ZM10.333 12.1667H3.66634V7.16668C3.66634 5.10001 4.92467 3.41668 6.99967 3.41668C9.07467 3.41668 10.333 5.10001 10.333 7.16668V12.1667Z"
                    fill="#6B7280"/>
				</svg>
				</span></a></li>
                    <li><a to={"#"}>Like it? share!<span className="link-icon"><svg width="18" height="18"
                                                                                    viewBox="0 0 18 18" fill="none"
                                                                                    xmlns="http://www.w3.org/2000/svg">
				<path
                    d="M6.23689 10.1183C6.40523 9.7816 6.50023 9.4016 6.50023 8.99993C6.50023 8.59827 6.40523 8.21827 6.23689 7.8816M6.23689 10.1183C5.98498 10.6219 5.5704 11.0258 5.06031 11.2644C4.55022 11.503 3.9745 11.5624 3.42643 11.433C2.87836 11.3036 2.39004 10.9929 2.04057 10.5513C1.69111 10.1097 1.50098 9.56308 1.50098 8.99993C1.50098 8.43679 1.69111 7.89014 2.04057 7.44854C2.39004 7.00694 2.87836 6.69627 3.42643 6.56685C3.9745 6.43743 4.55022 6.49684 5.06031 6.73546C5.5704 6.97409 5.98498 7.37794 6.23689 7.8816M6.23689 10.1183L11.7636 12.8816M6.23689 7.8816L11.7636 5.11827M11.7636 12.8816C11.467 13.4748 11.4182 14.1615 11.6279 14.7907C11.8376 15.4199 12.2887 15.94 12.8819 16.2366C13.4751 16.5332 14.1618 16.582 14.791 16.3723C15.4202 16.1625 15.9403 15.7115 16.2369 15.1183C16.5335 14.5251 16.5823 13.8383 16.3726 13.2092C16.1628 12.58 15.7118 12.0599 15.1186 11.7633C14.8248 11.6164 14.5051 11.5288 14.1775 11.5056C13.8499 11.4823 13.521 11.5237 13.2094 11.6276C12.5803 11.8373 12.0602 12.2884 11.7636 12.8816ZM11.7636 5.11827C11.9104 5.41193 12.1137 5.6738 12.3618 5.88891C12.6098 6.10403 12.8978 6.26817 13.2093 6.37198C13.5208 6.47579 13.8497 6.51723 14.1772 6.49393C14.5048 6.47063 14.8245 6.38304 15.1181 6.23618C15.4118 6.08932 15.6737 5.88606 15.8888 5.63799C16.1039 5.38993 16.2681 5.10192 16.3719 4.79042C16.4757 4.47892 16.5171 4.15002 16.4938 3.8225C16.4705 3.49499 16.3829 3.17527 16.2361 2.8816C15.9395 2.28851 15.4194 1.83753 14.7903 1.62788C14.1612 1.41823 13.4746 1.46708 12.8815 1.76368C12.2884 2.06028 11.8374 2.58034 11.6278 3.20945C11.4181 3.83855 11.467 4.52518 11.7636 5.11827Z"
                    stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>
				</span></a></li>
                    <li><a to={"#"} onClick={handleLogout}>Logout<span className="link-icon"><svg width="20" height="20" viewBox="0 0 20 20"
                                                                           fill="none"
                                                                           xmlns="http://www.w3.org/2000/svg">
				<path
                    d="M14.1667 13.3333L17.5 9.99999M17.5 9.99999L14.1667 6.66666M17.5 9.99999H5.83333M10.8333 13.3333V14.1667C10.8333 14.8297 10.5699 15.4656 10.1011 15.9344C9.63226 16.4033 8.99637 16.6667 8.33333 16.6667H5C4.33696 16.6667 3.70107 16.4033 3.23223 15.9344C2.76339 15.4656 2.5 14.8297 2.5 14.1667V5.83333C2.5 5.17029 2.76339 4.5344 3.23223 4.06556C3.70107 3.59672 4.33696 3.33333 5 3.33333H8.33333C8.99637 3.33333 9.63226 3.59672 10.1011 4.06556C10.5699 4.5344 10.8333 5.17029 10.8333 5.83333V6.66666"
                    stroke="#6B7280" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>
				</span></a></li>
                </ul>
            )}
        </div>
    );
}

export default Profile;
