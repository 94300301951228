import React, { useEffect, useRef } from 'react';
import 'daterangepicker/daterangepicker.css'; // Import the CSS file
import $ from 'jquery'; // Import jQuery
import 'daterangepicker';
import moment from "moment"; // Import the DateRangePicker library

const ReportRange = ({ onChange, startDate, endDate }) => {
  const pickerRef = useRef(null);

  useEffect(() => {
    const $picker = $(pickerRef.current);

    // Initialize the daterangepicker with default start and end dates
    $picker.daterangepicker({
      opens: 'left', // Adjust as needed
      locale: {
        format: 'YYYY-MM-DD', // Date format
      },
      startDate: startDate || moment(), // Default to today if startDate is not provided
      endDate: endDate || moment(), // Default to today if endDate is not provided
    });

    // Event listener for date range change
    $picker.on('apply.daterangepicker', (event, picker) => {
      const start = picker.startDate.format('YYYY-MM-DD');
      const end = picker.endDate.format('YYYY-MM-DD');
      // Call the onChange callback with selected start and end dates
      if (onChange) {
        onChange(start, end);
      }
    });

    // Cleanup function
    return () => {
      // Destroy the daterangepicker to prevent memory leaks
      $picker.data('daterangepicker').remove();
    };
  }, [onChange, startDate, endDate]);

  useEffect(() => {
    const $picker = $(pickerRef.current);
    // Update the date range in the daterangepicker when startDate or endDate props change
    $picker.data('daterangepicker').setStartDate(startDate || moment());
    $picker.data('daterangepicker').setEndDate(endDate || moment());
  }, [startDate, endDate]);

  // Conditional rendering for the date display
  const displayText = startDate && endDate
      ? `${moment(startDate).format('YYYY-MM-DD')} - ${moment(endDate).format('YYYY-MM-DD')}`
      : "Select Date Range";

  return (
      <div>
        <div id="reportrange" ref={pickerRef}>
        <span>
          <span className="telemetry-date-selector">{displayText}</span>
        </span>
        </div>
      </div>
  );
};

export default ReportRange;
