import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { fetchUsers } from "../../services/apiService";
import { useNavigate, useParams } from 'react-router-dom';
import { useTelemetryContext } from "../../contexts/TelemetryContext";

function UserTables({ paginationPerPage, searchTerm }) {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(paginationPerPage || 10);
    const [fetching, setFetching] = useState(false);
    const navigate = useNavigate();
    const { storeId, productId } = useParams();
    const { selectedProduct } = useTelemetryContext();

    useEffect(() => {
        if (selectedProduct && selectedProduct.id.toString() === productId) {
            const fetchData = async () => {
                setFetching(true);
                try {
                    const responseData = await fetchUsers(selectedProduct.id, currentPage, perPage, searchTerm);
                    setData(responseData.data);
                    setTotalRows(responseData.total);
                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setFetching(false);
                }
            };
            fetchData();
        }
    }, [selectedProduct, productId, currentPage, perPage, searchTerm, paginationPerPage]);    

    useEffect(() => {
        setPerPage(paginationPerPage);
    }, [paginationPerPage]);

    const handleUserDetailClick = (userId) => {
        navigate(`/stores/${storeId}/plugins/${productId}/users/${userId}`);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (newPerPage) => {
        setPerPage(newPerPage); 
        setCurrentPage(1);  
    };

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
            cell: row => (
                <span className="id-span link-icon" onClick={() => handleUserDetailClick(row.id)}>
                    {row.id}
                </span>
            ),
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            cell: row => (
                <span className="data-user">
                    <img src="https://via.placeholder.com/40" alt="avatar" />
                    <span className="link-icon" onClick={() => handleUserDetailClick(row.id)}>
                        {row.email}
                    </span>
                </span>
            ),
        },
        {
            name: 'Name',
            selector: row => row.nick_name,
            sortable: true,
            cell: row => <span className="id-span">{row.nick_name}</span>,
        },
        {
            name: 'Marketing',
            selector: row => row.marketing_record,
            sortable: true,
            cell: row => (
                <span className={"badge" + (row.marketing_record === 1 ? " green-badge" : " orange-badge")}>
                    {row.marketing_record === 1 ? 'Active' : 'Inactive'}
                </span>
            ),
        },
        {
            name: 'Member Since',
            selector: row => new Date(row.created_at).toLocaleDateString(),
            sortable: true,
        },
    ];

    return (
        <DataTable
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={perPage}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            responsive
            progressPending={fetching}
            progressComponent={(
                <div className="spinner-container">
                    <div className="spinner"></div>
                </div>
            )}
            highlightOnHover
            className="custom-data-table"
        />
    );
}

export default UserTables;
