import React, {useState} from 'react';
import SitesTable from "../tables/SitesTable";


const TelemetryAccordion = ({title,children}) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <div className="telemetry-settings-tab">
            <div className="telemetry-setting-tab-content">
                <div className="accordion">
                    <div className="accordion-item">
                        <div
                            className="accordion-title"
                            onClick={() => setIsActive(!isActive)}
                        >
                            <div>{title}</div>
                            <div>{isActive ? '-' : '+'}</div>
                        </div>
                        {isActive &&
                            <div className="accordion-content">
                                <div className="telemetry-data-table-container">
                                    {children}
                                </div>
                            </div>}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default TelemetryAccordion;
