import React, {useEffect, useState} from 'react';
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';
import './graph.css';
import {fetchDashboardChartData} from "../../../services/apiService";
import {processVersionsResults} from "../../../utils/dataProcessing";
import {colors} from '../../../utils/constants';

const VersionsStackedAreaChart = ({
                                      product_id,
                                      title,
                                      meta_key,
                                      group_by_period,
                                      start_date,
                                      end_date,
                                      compare_start_date,
                                      compare_end_date,
                                      showGrowth
                                  }) => {
    const [data, setData] = useState([]);
    const [versions, setVersions] = useState([]);
    const [hoveredPeriod, setHoveredPeriod] = useState(null);
    const [overallTotals, setOverallTotals] = useState({});
    const [periodGrowthData, setPeriodGrowthData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchChartData = async () => {
            try {
                const responseData = await fetchDashboardChartData(product_id, meta_key, group_by_period, start_date, end_date, compare_start_date, compare_end_date, showGrowth);
                const currentData = processVersionsResults(responseData.current, 'semantic_versioning') || [];
                const previousData = processVersionsResults(responseData.previous, 'semantic_versioning') || [];

                // Handle the case where there might be no data
                if (!currentData.length || (showGrowth && !previousData.length)) {
                    setLoading(false);
                    return; // No data to process
                }

                const latestPeriodData = currentData[currentData.length - 1];
                const topVersions = Object.entries(latestPeriodData || {})
                    .filter(([key]) => key !== 'period')
                    .sort((a, b) => b[1] - a[1])
                    .slice(0, 9)
                    .map(([version]) => version);

                setVersions(topVersions);

                // Normalize data across all periods
                const normalizedData = currentData.map(periodData => {
                    const normalizedPeriodData = {period: periodData.period};
                    topVersions.forEach(version => {
                        normalizedPeriodData[version] = periodData[version] || 0;
                    });
                    return normalizedPeriodData;
                });

                setData(normalizedData);

                // Always calculate overall totals
                const currentTotals = {};
                const previousTotals = {};
                topVersions.forEach(version => {
                    currentTotals[version] = normalizedData.reduce((sum, periodData) => sum + (periodData[version] || 0), 0);
                    previousTotals[version] = previousData.reduce((sum, periodData) => sum + (periodData[version] || 0), 0);
                });

                const growthData = {};
                topVersions.forEach(version => {
                    const currentTotal = currentTotals[version] || 0;
                    const previousTotal = previousTotals[version] || 0;
                    const growth = previousTotal === 0 ? 0 : ((currentTotal - previousTotal) / previousTotal) * 100;
                    growthData[version] = {currentTotal, previousTotal, growth: growth.toFixed(1)};
                });

                if (showGrowth) {
                    // Ensure previous period exists for growth calculation
                    const periodGrowthData = normalizedData.map((periodData) => {
                        const previousPeriodData = previousData.find(p => p.period === periodData.period) || {};
                        const periodGrowth = {};

                        topVersions.forEach(version => {
                            const currentTotal = periodData[version] || 0;
                            const previousTotal = previousPeriodData[version] || 0;
                            const growth = previousTotal === 0 ? 0 : ((currentTotal - previousTotal) / previousTotal) * 100;
                            periodGrowth[version] = {currentTotal, previousTotal, growth: growth.toFixed(1)};
                        });

                        return {
                            period: periodData.period,
                            data: periodGrowth,
                        };
                    });

                    setPeriodGrowthData(periodGrowthData);
                }

                setOverallTotals(growthData);
                setHoveredPeriod(null); // Default to overall totals
                setLoading(false);

            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };
        fetchChartData();
    }, [product_id, meta_key, group_by_period, start_date, end_date,compare_start_date, compare_end_date, showGrowth]);

    const handleMouseMove = (state) => {
        if (state && state.activePayload && state.activePayload.length) {
            setHoveredPeriod(state.activePayload[0].payload.period);
        } else {
            setHoveredPeriod(null);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    // Displaying either the overall total or hovered period total data
    const filteredData = showGrowth && hoveredPeriod
        ? periodGrowthData.find(item => item.period === hoveredPeriod)?.data || overallTotals
        : overallTotals;

    const period = hoveredPeriod ? hoveredPeriod : "Overall";

    const topVersionsData = versions.map(version => ({
        version,
        ...filteredData[version],
    }));

    // Calculating totals even when showGrowth is false
    const hoveredPeriodTotal = topVersionsData.reduce((sum, {currentTotal}) => sum + currentTotal, 0);
    const hoveredPeriodPreviousTotal = topVersionsData.reduce((sum, {previousTotal}) => sum + previousTotal, 0);
    const hoveredPeriodGrowth = hoveredPeriodPreviousTotal === 0 ? 0 :
        ((hoveredPeriodTotal - hoveredPeriodPreviousTotal) / hoveredPeriodPreviousTotal) * 100;

    return (
        <div className="telemetry-block-container">
            <div className="telemetry-block-column">
                <div className="telemetry-block-header green-badge">
                    <h3>{title}</h3>
                    <span className="badge"></span>
                </div>
                <div className="chart-container">
                    <ResponsiveContainer width="100%" height={495}>
                        <AreaChart
                            data={data}
                            margin={{top: 20, right: 30, left: 20, bottom: 5}}
                            onMouseMove={handleMouseMove}
                            onMouseLeave={() => setHoveredPeriod(null)}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="period"/>
                            <YAxis/>
                            <Tooltip/>
                            {versions.map((version, index) => (
                                <Area
                                    key={version}
                                    type="monotone"
                                    dataKey={version}
                                    stackId="1"
                                    stroke={colors[index % colors.length]}
                                    fill={colors[index % colors.length]}
                                />
                            ))}
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </div>
            <div className="telemetry-block-column">
                <div className="table-container">
                    <table>
                        <thead>
                        <tr>
                            <th>{title}</th>
                            <th>{period}</th>
                            {showGrowth && <th>Growth</th>}
                            {showGrowth && <th>Previous Total</th>}
                        </tr>
                        </thead>
                        <tbody>
                        {topVersionsData.map(({version, currentTotal, previousTotal, growth}, index) => (
                            <tr key={version}>
                                <td>
                                        <span className="badge"
                                              style={{backgroundColor: colors[index % colors.length]}}>
                                        </span> {version}
                                </td>
                                <td>{currentTotal}</td>
                                {showGrowth && (
                                    <>
                                        <td className={growth >= 0 ? 'positive-growth' : 'negative-growth'}>
                                                <span className='badge'>
                                                    {growth >= 1000
                                                        ? `${(growth / 1000).toFixed(1)}K%`
                                                        : growth < 0
                                                            ? `${growth}%`
                                                            : `+${growth}%`}
                                                </span>
                                        </td>
                                        <td>{previousTotal}</td>
                                    </>
                                )}
                            </tr>
                        ))}
                        </tbody>
                        {/* Always show total, but conditionally show growth column */}
                        <tfoot>
                        <tr>
                            <td>Total</td>
                            <td>{hoveredPeriodTotal}</td>
                            {showGrowth && (
                                <>
                                    <td className={hoveredPeriodGrowth >= 0 ? 'positive-growth' : 'negative-growth'}>
                                            <span className='badge'>
                                                {hoveredPeriodGrowth >= 1000
                                                    ? `${(hoveredPeriodGrowth / 1000).toFixed(1)}K%`
                                                    : hoveredPeriodGrowth < 0
                                                        ? `${hoveredPeriodGrowth.toFixed(1)}%`
                                                        : `+${hoveredPeriodGrowth.toFixed(1)}%`}
                                            </span>
                                    </td>
                                    <td>{hoveredPeriodPreviousTotal}</td>
                                </>
                            )}
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default VersionsStackedAreaChart;
