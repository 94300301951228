import {  Link } from "react-router-dom";
import React, { useState } from 'react';

function AddDropdown() {
  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle dropdown state
  const AddDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="add-project">
      {/* Toggle button */}
      <span onClick={AddDropdown}></span>

      {/* Dropdown content */}
      {isOpen && (
        <ul className="dd-style">
          <li><Link to={"/addnewstore"}>Store</Link></li>
		  <li><Link to={"/addnewproduct"}>Product</Link></li>
        </ul>
      )}
    </div>
  );
}

export default AddDropdown;
