import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { fetchUninstallReasons } from "../../../services/apiService";

const UninstallReasonsPieChart = ({ productId, title, meta_key, group_by_period, start_date, end_date }) => {
    const [chartData, setChartData] = useState([]);
    const [reasonData, setReasonData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                setChartData([]); // Clear existing chart data
                setReasonData([]); // Clear existing reason data

                // Call the fetchUninstallReasons from apiService with necessary parameters
                const data = await fetchUninstallReasons(productId, { meta_key, group_by_period, start_date, end_date });

                // Calculate the total count of uninstallations
                const total = data.reduce((acc, item) => acc + item.total, 0);

                // Prepare data for Pie chart with percentages
                const formattedData = data.map(item => ({
                    reason_detail: item.reason_detail,
                    value: parseFloat(((item.total / total) * 100).toFixed(2)) // Convert to percentage and parse as number
                }));

                setChartData(formattedData);

                // Store reason data for labels, with total percentages
                const percentageReasonData = data.map(item => ({
                    reason_detail: item.reason_detail,
                    total: ((item.total / total) * 100).toFixed(2) // Convert to percentage
                }));

                setReasonData(percentageReasonData);
            } catch (error) {
                console.error('Error fetching uninstallation reasons', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [productId, meta_key, group_by_period, start_date, end_date]);

    const COLORS = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#C9DE00'];

    // Function to format the label
    const renderLabel = ({ value }) => {
        return `${value}%`;
    };

    // Function to format the tooltip
    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip bold" style={{
                    backgroundColor: 'white',
                    padding: '0px 10px 0px 10px',
                    borderRadius: '5px',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)'
                }}>
                    <p>{`${payload[0].name} : ${payload[0].value}%`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <div>
            <div className="telemetry-block-container" style={{ width: '100%', height: '100%' }}>
                <div className="telemetry-block-column">
                    <div className="telemetry-block-header green-badge">
                        <span className="badge"></span>
                    </div>
                    <h3>{title}</h3>
                    <div style={{ width: '100%', height: 350 }}>
                        <ResponsiveContainer>
                            {chartData.length > 0 ? (
                                <PieChart>
                                    <Pie
                                        data={chartData}
                                        dataKey="value"
                                        nameKey="reason_detail"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={120}
                                        fill="#8884d8"
                                        label={renderLabel}
                                    >
                                        {chartData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip content={<CustomTooltip />} />
                                </PieChart>
                            ) : (
                                <p>Loading chart...</p>
                            )}
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="telemetry-block-column">
                    <div className="table-container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Uninstallation Reasons</th>
                                    <th>%</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reasonData.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <span className="badge" style={{ backgroundColor: COLORS[index % COLORS.length] }}></span>&nbsp;&nbsp;
                                            {item.reason_detail}
                                        </td>
                                        <td>{item.total}%</td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UninstallReasonsPieChart;
