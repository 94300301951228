import { useState, useEffect } from 'react';
import {Outlet, Link, useParams} from "react-router-dom";
import ToggleDropdown from '../reused-components/SidebarTabs';
import AddProducts from '../reused-components/AddProducts';
import Profile from '../reused-components/Profile';
import smlogo from '../../img/sm-logo.svg';
import profileImage from '../../img/user-profile.png';
import customizerLogo from '../../img/customizer-logo.svg';
import EventTypeFilter from "../reused-components/EventTypeFilter";
import StoreEventsLogTable from '../tables/StoreEventsLogTable';

export default function EventsLog() {
    document.title = "Product Events Log";

    const [productId, setProductId] = useState(null);
    const [filter, setFilter] = useState('all');
    const {storeId} = useParams();

    useEffect(() => {
        const fetchProductId = async () => {
            const id = 1; 
            setProductId(id);
        };
        fetchProductId();
    }, []);

    const handleFilterChange = (selectedFilter) => {
        setFilter(selectedFilter.toLowerCase());
    };

    return (
        <div className="telemetry-page-wrapper">
            <header className="telemetry-header">
                <div className="telemetry-logo-section">
                    <a href="#"><img src={smlogo} alt="Telemetry" /></a>
                </div>
                <div className="telemetry-top">
                    <h1>Product Events Log</h1>
                    <div className="telemetry-top-right">
                        <AddProducts />
                        <Profile image={profileImage} />
                    </div>
                </div>
            </header>
            <main className="telemetry-main">
                <aside className="telemetry-sidebar">
				<ToggleDropdown tabactive={0} tabactive2={0} />
                </aside>
                <section className="telemetry-content">
                    <div className="telemetry-filter">
                        <EventTypeFilter onFilterChange={handleFilterChange} />
                    </div>
                    <div className="telemetry-data-table-wrapper">
                            {productId ? (
                                <StoreEventsLogTable
                                    type={filter}
                                    storeId={storeId}
                                />
                            ) : (
                                <p>Loading...</p>
                            )}
                    </div>
                </section>
            </main>
        </div>
    );
}
