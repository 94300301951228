import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { fetchEventLogs } from "../../services/apiService";
import { useTelemetryContext } from "../../contexts/TelemetryContext";
import { useNavigate, useParams } from 'react-router-dom';

function ProductEventsLogTable({ type, paginationPerPage = 10, userId = null, siteId = null }) {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(paginationPerPage);
    const [fetching, setFetching] = useState(false);
    const isFirstRender = useRef(true);
    const { selectedProduct } = useTelemetryContext();
    const navigate = useNavigate();
    const { storeId, productId } = useParams();

    const fetchData = async (page, perPage) => {
        setFetching(true);
        try {
            const responseData = await fetchEventLogs(selectedProduct.id, page, perPage, type, userId, siteId);
            setData(responseData.data || []);
            setTotalRows(responseData.total || 0);
            setCurrentPage(responseData.current_page || page);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setFetching(false);
        }
    };
    
    useEffect(() => {
        if (productId) {
            fetchData(currentPage, perPage);
        }
    }, [productId, currentPage, perPage, type, userId, siteId]);
    
    useEffect(() => {
        if (selectedProduct?.id.toString() === productId) {
            fetchData(currentPage, perPage);
        }
    }, [selectedProduct?.id, productId, currentPage, perPage, type, userId, siteId]);
    
    

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (newPerPage) => {
        setPerPage(newPerPage);
        setCurrentPage(1); // Reset to the first page
    };

    const handleUserDetailClick = (userId) => {
        navigate(`/stores/${storeId}/plugins/${productId}/users/${userId}`);
    };

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
            cell: row => <span className="id-span">{row.id}</span>,
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
            cell: row => (
                <span className={`badge ${row.type === 'activate' ? 'green-badge' : 'orange-badge'}`}>
                    {row.type}
                </span>
            ),
        },
        {
            name: 'Created on',
            selector: row => row.created_at,
            sortable: true,
            cell: row => new Date(row.created_at).toLocaleString(),
        },
        {
            name: 'Install',
            selector: row => row.install,
            sortable: true,
        },
        {
            name: 'Trigger',
            selector: row => row.trigger,
            sortable: true,
            cell: row => (
                <span onClick={() => handleUserDetailClick(row.trigger.split('-')[1].trim())} className="link-icon">
                    {row.trigger}
                </span>
            ),
        },
        {
            name: 'Action',
            selector: row => row.action,
            sortable: true,
            cell: row => <a href="#">Retry all webhooks</a>,
        },
        {
            name: 'State',
            selector: row => row.state,
            sortable: true,
            cell: row => <span className="badge green-badge">processed</span>,
        },
    ];

    return (
        <DataTable
            columns={columns}
            data={data}
            progressPending={fetching}
            progressComponent={(
                <div className="spinner-container">
                    <div className="spinner"></div>
                </div>
            )}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={perPage}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            highlightOnHover
            responsive
            className="custom-data-table"
        />
    );
}

export default ProductEventsLogTable;
