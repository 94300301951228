import React from 'react';

function UserBlocks(props) {
  return (
    <div className={`telemetry-user-blocks ${ props.wrapper }`}>
	<div className={`telemetry-image ${ props.inner }`}>
		<img src={props.img} alt="All time Users"/>
	</div>
	<div className="telemetry-text">
		<h3>{props.number}</h3>
		<p>{props.userText} <span className="user-info" data-title={props.tooltip}></span></p>
		
		{props.up || props.down ? (<div className="telemetry-install-counters"><span className="telemetry-down">{props.up}</span><span className="telemetry-down">{props.down}</span></div>): ''}
	</div>
</div>
  );
}

export default UserBlocks;
