import React, { useState } from 'react';
import PluginForm from '../forms/addPluginForm';
import AddonForm from '../forms/addAddonForm';
import WidgetForm from '../forms/addWidgetForm';
import ThemeForm from '../forms/addThemeForm';
import TemplateForm from '../forms/addTemplateForm';
import SaasForm from '../forms/addSaasForm';
import BundleForm from '../forms/addBundleForm';
import MembershipForm from '../forms/addMemberShipForm';
const ProductSelection = () => {
  const [selectedProductType, setSelectedProductType] = useState('Plugin');

  const handleProductTypeChange = (event) => {
    setSelectedProductType(event.target.value);
  };

  return (
    <div className="select-product-wrapper">
      <h4>Product type:</h4>
      <div className="select-product-type">
        <label>
          <input
            name="product_type"
            type="radio"
            value="Plugin"
            checked={selectedProductType === 'Plugin'}
            onChange={handleProductTypeChange}
          />
          Plugin
        </label>
        {/* <label>
          <input
            name="product_type"
            type="radio"
            value="Add-on"
            checked={selectedProductType === 'Add-on'}
            onChange={handleProductTypeChange}
          />
          Add-on
        </label>
        <label>
          <input
            name="product_type"
            type="radio"
            value="Widget"
            checked={selectedProductType === 'Widget'}
            onChange={handleProductTypeChange}
          />
          Widget
        </label>
        <label>
          <input
            name="product_type"
            type="radio"
            value="Theme"
            checked={selectedProductType === 'Theme'}
            onChange={handleProductTypeChange}
          />
          Theme
        </label>
        <label>
          <input
            name="product_type"
            type="radio"
            value="Template"
            checked={selectedProductType === 'Template'}
            onChange={handleProductTypeChange}
          />
          Template
        </label>
        <label>
          <input
            name="product_type"
            type="radio"
            value="Saas"
            checked={selectedProductType === 'Saas'}
            onChange={handleProductTypeChange}
          />
          Saas
        </label>
        <label>
          <input
            name="product_type"
            type="radio"
            value="Bundle"
            checked={selectedProductType === 'Bundle'}
            onChange={handleProductTypeChange}
          />
          Bundle
        </label>
        <label>
          <input
            name="product_type"
            type="radio"
            value="Membership"
            checked={selectedProductType === 'Membership'}
            onChange={handleProductTypeChange}
          />
          Membership
        </label> */}
      </div>

      {selectedProductType === 'Plugin' && <div action="#" method="post" data-form="Plugin"><PluginForm/></div>}
      {selectedProductType === 'Add-on' && <form action="#" method="post" data-form="Add-on"><AddonForm/></form>}
      {selectedProductType === 'Widget' && <form action="#" method="post" data-form="Widget"><WidgetForm/></form>}
      {selectedProductType === 'Theme' && <form action="#" method="post" data-form="Theme"><ThemeForm/></form>}
      {selectedProductType === 'Template' && <form action="#" method="post" data-form="Template"><TemplateForm/></form>}
      {selectedProductType === 'Saas' && <form action="#" method="post" data-form="Saas"><SaasForm/></form>}
      {selectedProductType === 'Bundle' && <form action="#" method="post" data-form="Bundle"><BundleForm/></form>}
      {selectedProductType === 'Membership' && <form action="#" method="post" data-form="Membership"><MembershipForm/></form>}
    </div>
  );
};

export default ProductSelection;
