import React from 'react';

function ToggleDropdown({activeTab, setActiveTab, setIsTelemetryVisible, isTelemetryVisible}) {
    const handleClick = (tabIndex) => {
        setActiveTab(tabIndex);
        if(isTelemetryVisible) {
            setIsTelemetryVisible(false);
        } else {
            setIsTelemetryVisible(true);
        }
    };

    return (
        <div className="telemetry-projects-dd">
      <span
          className={activeTab === 0 ? 'active' : ''}
          onClick={() => handleClick(0)}
      >
        Store
      </span>
            <span
                className={activeTab === 1 ? 'active' : ''}
                onClick={() => handleClick(1)}
            >
        Products
      </span>
        </div>
    );
}

export default ToggleDropdown;
