import React from 'react';

function ProductBlock(props) {
    return (
        <div className={"product-block"} onClick={props.handleProductClick(props.product)}>
            <div className={`telemetry-user-blocks telemetry-user-block-blue`}>
                <div className={`telemetry-image ${props.inner}`}>
                    <img src={props.img} alt="All time Users"/>
                </div>
                <div className="telemetry-text">
                    <h3>{props.number}</h3>
                    <p>{props.userText} <span className="user-info" data-title={props.tooltip}></span></p>
                    <span className="telemetry-up">View Dashboard</span>
                </div>
            </div>
        </div>
    );
}

export default ProductBlock;
