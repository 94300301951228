import React, { useEffect, useState } from 'react';
import {getGroupByValues} from "../../utils/dataProcessing";



function GroupByDropdown({ handleGroupByDropdownClick, startDate, endDate, group_by_period }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedGroupBy, setSelectedGroupBy] = useState('');
  const [groupByValues, setGroupByValues] = useState([]);

  useEffect(() => {
    const values = getGroupByValues(startDate, endDate);
    setGroupByValues(values);
    const defaultGroupBy = values.find(value => value.value === group_by_period) || values[0];
    setSelectedGroupBy(defaultGroupBy.label); // Set default to the matching value or the first value
  }, [startDate, endDate, group_by_period]);

  const handleState = () => {
    setIsOpen(!isOpen);
  };

  const handleGroupByClick = (groupBy) => {
    setSelectedGroupBy(groupBy.label); // Update dropdown text
    handleGroupByDropdownClick(groupBy.value); // Pass the key value to the parent
    setIsOpen(false); // Close dropdown after selection
  };

  return (
      <div className="telemetry-select-dd move-right no-seprator">
        {/* Toggle button */}
        <span onClick={handleState}>{selectedGroupBy}</span>
        {/* Dropdown content */}
        {isOpen && (
            <ul className="telemetry-dd">
              {groupByValues.map((groupBy, index) => (
                  <li key={index} onClick={() => handleGroupByClick(groupBy)}>
                    {groupBy.label}
                  </li>
              ))}
            </ul>
        )}
      </div>
  );
}

export default GroupByDropdown;
