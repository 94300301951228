import {Link, useParams} from "react-router-dom";
import React, { useState, useEffect } from 'react';
import ToggleDropdown from '../reused-components/SidebarTabs';
import Profile from '../reused-components/Profile';
import smlogo from '../../img/sm-logo.svg';
import AddProducts from '../reused-components/AddProducts';
import profileImage from '../../img/user-profile.png';
import profileicon from '../../img/customer-icon.svg';
import Notification from '../reused-components/Notification';
import { fetchStores, updateStoreData } from '../../services/apiService';

export default function Settings() {

    // State variables for form fields
    const [storeTitle, setStoreTitle] = useState('');
    const [storeSlug, setStoreSlug] = useState('');
    const [termsConditions, setTermsConditions] = useState('');
    const [supportForm, setSupportForm] = useState('');
    const [storeIcon, setStoreIcon] = useState(null);
    const [dashboardUrl, setDashboardUrl] = useState('');
    const [dashboardCss, setDashboardCss] = useState('');
    const [dashboardJs, setDashboardJs] = useState('');
    const [iconFileName, setIconFileName] = useState('');
    const [storeLogoUrl, setStoreLogoUrl] = useState('');
    const [showSupportContactForm, setShowSupportContactForm] = useState(false);

    // Form validation errors
    const [errors, setErrors] = useState({});
    const {storeId} = useParams();

    // Fetch the store data when the component mounts
    useEffect(() => {
        const fetchStoreData = async () => {
            try {
                const data = await fetchStores(storeId);
                setStoreTitle(data.title || '');
                setStoreSlug(data.web_url || '');
                setTermsConditions(data.terms_and_conditions_url || '');
                setSupportForm(data.support_form_url || '');
                setDashboardUrl(data.user_dashboard_url || '');
                setDashboardCss(data.user_dashboard_css || '');
                setDashboardJs(data.user_dashboard_js || '');
                setStoreLogoUrl(data.logo_url || '');
                setShowSupportContactForm(data.show_support_contact_form || false);
            } catch (error) {
                console.error("Failed to fetch store data:", error);
            }
        };

        fetchStoreData();
    }, [storeId]);

    // Form validation logic
    const validateForm = () => {
        const newErrors = {};
        if (!storeTitle) newErrors.storeTitle = "Store Title is required.";
        if (!storeSlug) newErrors.storeSlug = "Store Slug is required.";
        if (!dashboardUrl) newErrors.dashboardUrl = "Dashboard URL is required.";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Form submission handler
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) return; // Stop submission if validation fails
    
        const storeData = {
            title: storeTitle,
            web_url: storeSlug,
            terms_and_conditions_url: termsConditions,
            support_form_url: supportForm,
            user_dashboard_url: dashboardUrl,
            user_dashboard_css: dashboardCss,
            user_dashboard_js: dashboardJs,
            show_support_contact_form: showSupportContactForm,
        };
        if (storeIcon) {
            // Append the file separately if necessary or handle it differently
        }
    
        try {
            const result = await updateStoreData(storeId, storeData);
            console.log('Store updated successfully:', result);
        } catch (error) {
            console.error("Failed to update store data:", error);
        }
    };
    
    // Handle file selection for the icon
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setStoreIcon(file);
        setIconFileName(file ? file.name : '');
    };

    document.title = "Settings | Telemetry";
    return (
        <div className="telemetry-page-wrapper">
            <header className="telemetry-header">
                <div className="telemetry-logo-section">
                    <Link to={"/dashboard/"}><img src={smlogo} alt="Telemetry" /></Link>
                </div>
                <div className="telemetry-top">
                    <h1>Settings</h1>
                    <div className="telemetry-top-right">
                        <AddProducts />
                        <Profile image={profileImage} />
                    </div>
                </div>
            </header>
            <main className="telemetry-main">
                <aside className="telemetry-sidebar">
                    <ToggleDropdown tabactive={0} tabactive2={0} />
                </aside>
                <section className="telemetry-content">
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                        <div className="telemetry-setting-tab-content">
                            <div className="telemetry-product">
                                <h2>Store Settings</h2>
                                <div className="telemetry-add-products">
                                    <div>
                                        <div className="telemetry-form-inline">
                                            <h4>Store Title:</h4>
                                            <div className="telemetry-form-box">
                                                <input
                                                    type="text"
                                                    placeholder="Enter the title"
                                                    value={storeTitle}
                                                    onChange={(e) => setStoreTitle(e.target.value)}
                                                />
                                                {errors.storeTitle && <p className="error-message">{errors.storeTitle}</p>}
                                            </div>
                                        </div>
                                        <div className="telemetry-form-inline">
                                            <h4>Store URL:</h4>
                                            <div className="telemetry-form-box">
                                                <input
                                                    type="text"
                                                    placeholder="Enter the URL"
                                                    value={storeSlug}
                                                    onChange={(e) => setStoreSlug(e.target.value)}
                                                />
                                                {errors.storeSlug && <p className="error-message">{errors.storeSlug}</p>}
                                            </div>
                                        </div>
                                        <div className="telemetry-form-inline">
                                            <h4>Terms & Conditions URL:</h4>
                                            <div className="telemetry-form-box">
                                                <input
                                                    type="url"
                                                    placeholder="https://example.com/terms-and-conditions/"
                                                    value={termsConditions}
                                                    onChange={(e) => setTermsConditions(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="telemetry-form-inline">
                                            <h4>Support Form URL:</h4>
                                            <div className="telemetry-form-box">
                                                <input
                                                    type="url"
                                                    placeholder="https://example.com/support-form/"
                                                    value={supportForm}
                                                    onChange={(e) => setSupportForm(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="telemetry-form-inline">
                                            <h4>Store Logo:</h4>
                                            <div className="telemetry-form-box">
                                                <img src={storeLogoUrl} alt="Store Logo" style={{ maxWidth: '100px' }} />
                                            </div>
                                        </div>
                                        <div className="telemetry-form-inline">
                                            <h4>Store Icon:</h4>
                                            <div className="telemetry-form-box">
                                                <div className="telemetry-upload-logo">
                                                    <div className="update-icon">
                                                        <img src={profileicon} alt="Profileicon" />
                                                    </div>
                                                    <div className="upload-action">
                                                        <label>
                                                            Upload <input type="file" onChange={handleFileChange} />
                                                        </label>
                                                        <p>{iconFileName || "200px X 200px | Up to 200KB"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="telemetry-form-inline">
                                            <h4>Show Support Contact Form:</h4>
                                            <div className="telemetry-form-box">
                                                <input
                                                    type="checkbox"
                                                    checked={showSupportContactForm}
                                                    onChange={(e) => setShowSupportContactForm(e.target.checked)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="telemetry-product">
                                <h2>User Dashboard</h2>
                                <div className="telemetry-add-products">
                                    <Notification content="Learn how to embed the User Dashboard right into your website" />
                                    <div>
                                        <div className="telemetry-form-inline">
                                            <h4>User Dashboard URL:</h4>
                                            <div className="telemetry-form-box">
                                                <input
                                                    type="url"
                                                    placeholder="https://example.com/account/"
                                                    value={dashboardUrl}
                                                    onChange={(e) => setDashboardUrl(e.target.value)}
                                                />
                                                {errors.dashboardUrl && <p className="error-message">{errors.dashboardUrl}</p>}
                                            </div>
                                        </div>
                                        <div className="telemetry-form-inline">
                                            <h4>User Dashboard CSS Stylesheet:</h4>
                                            <div className="telemetry-form-box">
                                                <input
                                                    type="url"
                                                    placeholder="https://example.com/assets/css/custom.css"
                                                    value={dashboardCss}
                                                    onChange={(e) => setDashboardCss(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="telemetry-form-inline">
                                        <h4>User Dashboard JavaScript:</h4>
                                        <div className="telemetry-form-box">
                                            <input
                                                type="url"
                                                placeholder="https://my-shop.com/assets/js/user-dashboard-tweaks.js"
                                                value={dashboardJs}
                                                onChange={(e) => setDashboardJs(e.target.value)}
                                            />
                                            <p>Adding custom JavaScript can override certain User Dashboard features, like expanding and collapsing panels.</p>
                                        </div>
                                    </div>
                                    <button type="submit">Save</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </main>
        </div >
    );
}
