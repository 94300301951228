import React, { useState } from 'react';

function OpenDD({handlePerPageClick}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('All');

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (e) => {
    const newValue = e.target.getAttribute('data-length');
    setSelectedValue(newValue === '-1' ? 'All' : newValue);
    setIsOpen(false);
    handlePerPageClick(newValue === '-1' ? 'All' : newValue);
  };

  return (
    <div className="telemetry-select-dd no-seprator telemetry-change-length">
      <span onClick={toggleDropdown}>{selectedValue}</span>

      {/* Dropdown content */}
      {isOpen && (
        <ul className="telemetry-dd">
          <li onClick={handleItemClick} data-length="-1">All</li>
          <li onClick={handleItemClick} data-length="10">10</li>
          <li onClick={handleItemClick} data-length="20">20</li>
          <li onClick={handleItemClick} data-length="50">50</li>
          <li onClick={handleItemClick} data-length="100">100</li>
        </ul>
      )}
    </div>
  );
}

export default OpenDD;
