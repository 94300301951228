import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { fetchStoreEventLogs } from "../../services/apiService"; // Assume fetchSiteEventLogs is your new API function
import { useTelemetryContext } from "../../contexts/TelemetryContext";
import { useNavigate } from 'react-router-dom';


function StoreEventsLogTable({ type, paginationPerPage = 10, userId = null, siteId = null, storeId }) {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(paginationPerPage);
    const navigate = useNavigate()


    useEffect(() => {
        const fetchData = async () => {
            try {
                let responseData;
                responseData = await fetchStoreEventLogs(storeId, currentPage, type, userId, siteId);
                setData(responseData.data || []);
                setCurrentPage(responseData.current_page || 1);
                setTotalRows(responseData.total || 0);
                setPerPage(responseData.per_page || paginationPerPage);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [storeId, type, currentPage, paginationPerPage, userId, siteId]);

    const handleUserDetailClick = (userId) => {
        const handleUserDetailClick = (userId) => {
            // Todo
            // Redirect to User detail page only without product
        };
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
            sortable: true,
            cell: row => <span className="id-span">{row.id}</span>,
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
            cell: row => (
                <span className={`badge ${row.type === 'activate' ? 'green-badge' : 'orange-badge'}`}>
                    {row.type}
                </span>
            ),
        },
        {
            name: 'Created on',
            selector: row => row.created_at,
            sortable: true,
            cell: row => new Date(row.created_at).toLocaleString(),
        },
        {
            name: 'Install',
            selector: row => row.install,
            sortable: true,
        },
        {
            name: 'Trigger',
            selector: row => row.trigger,
            sortable: true,
            cell: row => <span onClick={() => handleUserDetailClick(row.trigger.split('-')[1].trim())}
                className="link-icon" >{row.trigger}</span>,
        },
        {
            name: 'Action',
            selector: row => row.action,
            sortable: true,
            cell: row => <a href="#">Retry all webhooks</a>,
        },
        {
            name: 'State',
            selector: row => row.state,
            sortable: true,
            cell: row => <span className="badge green-badge">processed</span>,
        },
    ];
    return (
        <DataTable
            columns={columns}
            data={data}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={perPage}
            onChangePage={handlePageChange}
            responsive
            className="custom-data-table"
        />
    );
}

export default StoreEventsLogTable;
