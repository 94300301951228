import './App.css';
import React, {useState} from "react";
import './Scripts.js';
import Login from './components/Login';
import Dashboard from './components/products/Dashboard';
import Users from './components/products/Users';
import Sites from './components/products/Sites';
import EventsLog from './components/products/EventsLog';
import Activation from './components/products/Activation';
import Settings from './components/products/Settings';
import SiteDetails from './components/products/SiteDetails';
import Profile from './components/Profile';
import DashboardStore from './components/stores/StoreDashboard';


import EventsLogStore from './components/stores/StoreEventsLog';
import ActivationStore from './components/stores/ActivationStore';
import SettingsStore from './components/stores/StoreSettings';
import ProfileStore from './components/stores/ProfileStore';
import faviconLogo from './img/favicon.png';
import {HashRouter, Routes, Route} from "react-router-dom";
import Favicon from "react-favicon";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import UserDetail from "./components/products/UserDetail";
import AddNewProduct from './components/products/AddNewProduct';
import SDKIntegration from './components/products/SDKIntegration';

import StoreSites from './components/stores/StoreSites';
import StoreUsers from './components/stores/StoreUsers';
import StoreEventsLog from './components/stores/StoreEventsLog';
import StoreSettings from './components/stores/StoreSettings';
import AddNewStore from './components/stores/AddNewStore';


import {TelemetryProvider} from "./contexts/TelemetryContext";
import StoreDashboard from "./components/stores/StoreDashboard";




function App() {
    const [faviconUrl, setFaviconUrl] = useState(faviconLogo);

    return (
        <div className="App">
            <Favicon url={faviconUrl}/>
            <HashRouter>
                    <Routes>
                        <Route path="/" element={<PublicRoute><Login/></PublicRoute>}/>

                        {/* Selected Store Routes */}
                        <Route
                            path="stores/:storeId/dashboard"
                            element={<PrivateRoute><StoreDashboard/></PrivateRoute>}
                        />
                        <Route
                            path="stores/:storeId/sites"
                            element={<PrivateRoute><StoreSites/></PrivateRoute>}
                        />
                        <Route
                            path="stores/:storeId/users"
                            element={<PrivateRoute><StoreUsers/></PrivateRoute>}
                        />
                        <Route
                            path="stores/:storeId/eventslog"
                            element={<PrivateRoute><StoreEventsLog/></PrivateRoute>}
                        />
                        <Route
                            path="stores/:storeId/settings"
                            element={<PrivateRoute><StoreSettings/></PrivateRoute>}
                        />
                        <Route
                            path="add-store"
                            element={<PrivateRoute><AddNewStore/></PrivateRoute>}
                        />

                        {/* Selected Product Routes */}
                        <Route
                            path="stores/:storeId/plugins/:productId/dashboard"
                            element={<PrivateRoute><Dashboard/></PrivateRoute>}
                        />
                        <Route
                            path="stores/:storeId/plugins/:productId/users"
                            element={<PrivateRoute><Users/></PrivateRoute>}
                        />
                        <Route
                            path="stores/:storeId/plugins/:productId/users/:userId"
                            element={<PrivateRoute><UserDetail/></PrivateRoute>}
                        />
                        <Route
                            path="stores/:storeId/plugins/:productId/sites"
                            element={<PrivateRoute><Sites/></PrivateRoute>}
                        />
                        <Route
                            path="stores/:storeId/plugins/:productId/sites/:siteId"
                            element={<PrivateRoute><SiteDetails/></PrivateRoute>}
                        />
                        <Route
                            path="stores/:storeId/plugins/:productId/eventslog"
                            element={<PrivateRoute><EventsLog/></PrivateRoute>}
                        />
                        <Route
                            path="stores/:storeId/plugins/:productId/settings"
                            element={<PrivateRoute><Settings/></PrivateRoute>}
                        />
                        <Route
                            path="add-product"
                            element={<PrivateRoute><AddNewProduct/></PrivateRoute>}
                        />
                        <Route
                            path="stores/:storeId/plugins/:productId/integration"
                            element={<PrivateRoute><SDKIntegration/></PrivateRoute>}
                        />
                        


                    </Routes>
            </HashRouter>
        </div>
    );
}

export default App;
