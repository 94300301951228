import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { useTelemetryContext } from "../contexts/TelemetryContext";

const PublicRoute = ({ children }) => {
    const { token } = useContext(AuthContext);
    const { selectedProduct } = useTelemetryContext();

    // Check if token is available and selectedProduct is loaded
    if (token) {
        if (selectedProduct && selectedProduct.store_id && selectedProduct.id) {
            // Redirect to the specific path if selectedProduct is available
            return <Navigate to={`/stores/${selectedProduct.store_id}/plugins/${selectedProduct.id}/dashboard`} />;
        } else {
            // Display a loading or placeholder component while waiting for selectedProduct
            return <div>Loading...</div>; // You can replace this with a more sophisticated loading component if needed
        }
    }

    return children;
};

export default PublicRoute;