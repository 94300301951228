export const processAudienceActivitiesResults = (results) => {
    const customers = results.customers.map(event => ({
        period: event.period,
        total: event.total
    }));

    const sites = results.sites.map(event => ({
        period: event.period,
        total: event.total
    }));

    const productEvents = results.product_events.map(event => ({
        period: event.period,
        total_activations: event.total_activations,
        total_deactivations: event.total_deactivations,
        growth: event.total_activations - event.total_deactivations
    }));

    return {customers, sites, productEvents};
};

export const processVersionsResults = (results, type = "") => {
    let formattedData = {};

    if (type === "semantic_versioning") {
        results.forEach(result => {
            let period = result.period;
            let version = "v"+result.version.slice(0, 3).toString(); // Get the major version
            let total = result.total;

            if (!formattedData[period]) {
                formattedData[period] = {period: period};
            }

            if (!formattedData[period][version]) {
                formattedData[period][version] = 0;
            }

            formattedData[period][version] += total;
        });
    } else {
        results.forEach(result => {
            let period = result.period;
            let version = result.version; // Change to handle locale
            let total = result.total;

            if (!formattedData[period]) {
                formattedData[period] = {period: period};
            }

            if (!formattedData[period][version]) {
                formattedData[period][version] = 0;
            }

            formattedData[period][version] += total;
        });
    }

    // Convert associative array to indexed array
    let finalData = Object.values(formattedData);

    // Process each period to sort and limit the versions
    finalData = finalData.map(data => {
        let period = data.period;
        delete data.period;

        // Sort by total in descending order
        let sortedData = Object.entries(data).sort((a, b) => b[1] - a[1]);

        // Get top 9 and calculate the sum of the rest
        let topVersions = sortedData.slice(0, 9).reduce((acc, [version, total]) => {
            acc[version] = total;
            return acc;
        }, {});
        let otherTotal = sortedData.slice(9).reduce((sum, [version, total]) => sum + total, 0);

        // Add "other" if there are more than 9 versions
        if (otherTotal > 0) {
            topVersions['other'] = otherTotal;
        }

        // Restore the period key
        topVersions['period'] = period;
        return topVersions;
    });

    return finalData;
};

export const processEffectiveGrowthResults = (results) => {
    return results.product_events.map(event => ({
        period: event.period,
        total_activations: event.total_activations,
        total_deactivations: -event.total_deactivations, // Negate deactivations to show them below the axis
        growth: event.growth
    }));
};

export const getGroupByValues = (startDate, endDate) => {
    const msInDay = 24 * 60 * 60 * 1000;
    const msInWeek = 7 * msInDay;
    const msInMonth = 30 * msInDay;
    const msInYear = 365 * msInDay;

    const dateRange = new Date(endDate) - new Date(startDate);

    if (dateRange < msInWeek) {
        return [
            { label: 'Hourly (GMT)', value: 'hour' },
            { label: 'Daily (GMT)', value: 'day' }
        ];
    } else if (dateRange < msInMonth) {
        return [
            { label: 'Daily (GMT)', value: 'day' },
            { label: 'Weekly (GMT)', value: 'week' }
        ];
    } else if (dateRange < msInYear) {
        return [
            { label: 'Weekly (GMT)', value: 'week' },
            { label: 'Monthly (GMT)', value: 'month' }
        ];
    } else {
        return [
            { label: 'Monthly (GMT)', value: 'month' },
            { label: 'Yearly (GMT)', value: 'year' }
        ];
    }
};

export const getDefaultGroupByValue = (startDate, endDate) => {
    const groupByValues = getGroupByValues(startDate, endDate);
    return groupByValues[1].value; // Return the value of the first option
};